import React from 'react';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form'


export const KlaritiTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
   // which we can spread on <input>. We can use field meta to show an error
   // message if the field is invalid and it has been touched (i.e. visited)

   const [field, meta] = useField(props);
   return (
     <>
    <Form.Label htmlFor={props.id || props.name}>
      {label}
    </Form.Label>
    <Form.Control 
     type='text'
     {...field}
     {...props}
     />
       {meta.touched && meta.error ? (
        <div className="alert alert-warning error">{meta.error}</div>
      ) : null}
   </>
  );
  }

  export const KlaritiSelect = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
      <Form.Label htmlFor={props.id || props.name}>
        {label}
      </Form.Label>
      <Form.Control 
       as= "select"
       {...field}
       {...props}
       />
         {meta.touched && meta.error ? (
          <div className="alert alert-warning error">{meta.error}</div>
        ) : null}
     </>

    );
  };


  export const KlaritiCheckbox = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
      <div>
        <Form.Check
        type="checkbox"
        {...field}
        {...props}
        label={children} />
         {meta.touched && meta.error ? (
          <div className="alert alert-warning error">{meta.error}</div>
        ) : null}
      </div>
    );
  };

