/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:9c93973f-7b49-4b91-afb1-c5a26b1e600c",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_DzI9ovvtR",
    "aws_user_pools_web_client_id": "5mbii5hobneupmor13frbpeknl",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wo4oyz2uwnh3digdqqtga7mjwi.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
