import React from 'react';


function BookingRow(props) {



return(
  <tr>
  <th scope="row">{props.id}</th>
  <td>{props.firstName}</td>
  <td>{props.lastName}</td>
  <td>{props.email}</td>
  <td>{props.dateOfBirth}</td>
  <td>{props.postcode}</td>
  <td>{props.clientGender}</td>
  <td>{props.surgeon}</td>
  <td>{props.dateOfProcedure}</td>
  <td>{props.timeOfProcedure}</td>
  <td>{props.acceptedTerms}</td>

</tr>
);
}

export default BookingRow;