import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';

function KlaritiGreeting(props) {


  return (
    <>
    <Jumbotron className="p-3 m-4" > 
    <h1>Hello <b>{props.user}</b> from <b>{props.clinic}</b></h1>
  <p>
  Please use the links in the Navigation Bar to book a Klariti service on behalf of your surgeons, {props.surgeons} .<br/> 
         
          Bookings, and their status, may be found via the link above.</p>

          <p>The buttons below can also be used; this needs to be reworded well.</p>

          <hr />
          <p className="mb-0">
          If you experience issues with this site please email islabarnard@keratify.com
          Or call us on 012345678910, thank you.</p>
  
 
    <Button variant="primary" href='/makebooking'>Make Booking</Button>
  

    <Button variant="secondary" href='/viewbookings'>View Bookings</Button>
  
  </Jumbotron>


      <Alert variant="warning" className="p-1 m-3 w-75">
        <Alert.Heading> (Home Page) Testers, please read</Alert.Heading>
        <p>
          Known issues being fixed on this page:
   <ul>
            <li>Home button stays 'selected' (white) when you click away from it.</li>
          </ul>
          </p>
          <hr />
          <p>
        Any notes on appearance, layout, content, etc., let me know.
        This site is built using a commonly utilised user interface library called React Bootstrap,
        which works by breaking things into components.<br />
    
        Colours can be altered but rather than doing so on a component-by-component basis, its best to alter the entire theme 
        with colours that work together, to maintain a coherent site. I've chosen a theme
        using the adobe colour wheel <Alert.Link href='https://color.adobe.com/create/color-wheel'> here</Alert.Link>
        
  </p>
      </Alert>

    </>
  )

}


export default KlaritiGreeting;
