import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from "react-router-bootstrap"
import { AmplifySignOut } from '@aws-amplify/ui-react';
import logo from './01-02.png';


function KlaritiNavbar(props) {
    return (
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand>

                <img src={logo}
                    height="40"
                    className="d-inline-block align-top"
                />
            </Navbar.Brand>
            <Nav className="mr-auto">
                <LinkContainer to="/">
                    <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/makebooking">
                    <Nav.Link>Make Booking</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/viewbookings">
                    <Nav.Link >View Bookings</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/courier">
                    <Nav.Link >Courier Details</Nav.Link>
                </LinkContainer>
                <Nav.Item>(this is a NavBar)</Nav.Item>
            </Nav>

            <Navbar.Text className="p-3">
                Signed in as: {props.user}
            </Navbar.Text>

            <AmplifySignOut />
        </Navbar>
    )

}


export default KlaritiNavbar;
