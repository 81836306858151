import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { API } from 'aws-amplify';
import {createBooking as createBookingMutation } from '../graphql/mutations';

import { KlaritiTextInput, KlaritiCheckbox, KlaritiSelect } from './klaritiformik';


//this component simpy grabs state of the form
//and passes it back into the app


function FormikClientInfo(props) {

  async function createBooking(values) {
    console.log('hello'); 
    console.log(values);
    await API.graphql({ query:createBookingMutation, variables: {input: values} });
  }


  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  return (
    <> 
     <Alert variant="warning" className="p-1 m-3">
        <Alert.Heading>Make Booking Page: Testers, please read </Alert.Heading>
        
          Known issues being fixed on this page (these are now top priority)
   <ul>
            <li>Date of birth input method is terrible</li>
            <li>Date of procedure input method allows procedures in the past</li>
            <li>Surgeon not yet populated with real surgeons</li>
          </ul>
          <hr />
        Again, notes on appearance, layout, content, logic and validation etc., let me know and I'll get changes made. <br />
        The above component is a <Alert.Link href="https://react-bootstrap.github.io/components/cards/"> Card </Alert.Link>.<br />
        The card contains a <Alert.Link href="https://react-bootstrap.github.io/components/forms/"> Form</Alert.Link>.
        Forms are made of inputs and labels for the inputs, and here they are arranged in a grid system. <br />
        The form is submitted by a <Alert.Link href="https://react-bootstrap.github.io/components/buttons/"> Button </Alert.Link>.<br />
        On submission, an alert window pops up; this is a <Alert.Link href="https://react-bootstrap.github.io/components/modal/">Modal </Alert.Link>.<br />
        Currently, the above is a 'primary' card with a 'primary' submission button; using bootstrap's default <Alert.Link href="https://getbootstrap.com/docs/5.0/customize/color/">themes</Alert.Link>.
        This alert, which is only for this version under test, is themed as a 'warning'.

      </Alert>
    <Modal show={showModal} onHide={handleCloseModal} className="primary">
        <Modal.Header closeButton>
          <Modal.Title>(title) Booking Alert </Modal.Title>
        </Modal.Header>
        <Modal.Body> (body) Klariti services will be in touch by email within 48 hrs to confirm your booking. 
          (Changes to text, layout, colours for this alert box are all easy to do let me know) </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    
    <Card border='primary' bg='light' className="card p-1 m-3 w-75">

      <Card.Body>
        <Card.Title> (This is the Title) Add New Client</Card.Title>
        <Card.Subtitle className="mb-2 text-muted"> (This is the Subtitle) This is a Card, containing a Form. Scroll down for directions for feedback</Card.Subtitle>

        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            dateOfBirth: '',
            postcode: '',
            clientGender: '',
            procedureType: '',
            surgeon: '',
            dateOfProcedure: '',
            timeOfProcedure: '',
            acceptedTerms: false,
          }}

          validationSchema={Yup.object({
            firstName: Yup.string()
              .max(15, 'Must be 15 characters or less')
              .required('Required'),
            lastName: Yup.string()
              .max(20, 'Must be 20 characters or less')
              .required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            dateOfBirth: Yup.date()
              .required('Required'),
            postcode: Yup.string()
              .required('Required'),
            clientGender: Yup.string()
              .oneOf(
                ['male', 'female'])
              .required('Required'),
            procedureType: Yup.string()
              .oneOf(['procedure1', 'procedure2', 'procedure3', 'other'])
              .required('Required'),
            surgeon: Yup.string()
              .oneOf(['surgeon1', 'surgeon2', 'other'])
              .required('Required'),
            dateOfProcedure: Yup.date()
              .required('Required'),
            timeOfProcedure: Yup.string()
              .required('Required'),
            acceptedTerms: Yup.boolean()
              .required('Required')
              .oneOf([true], 'The client must accept the terms and conditions.'),
          })}



          onSubmit={(values, { setSubmitting, resetForm },) => {
            setTimeout(() => {
              // alert(values);
              createBooking(values);
              // props.addClientSample(values);
              setSubmitting(false);
              handleShowModal();
              resetForm();
            }, 600);
          }}

        >

          {props => (
            <Form>


              <Row className='p-1'>
                <Col>
                  <KlaritiTextInput
                    label="First Name (form labels)"
                    name="firstName"
                    placeholder="First Name"
                  />
                </Col>
                <Col>
                  <KlaritiTextInput
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                  />
                </Col>
                <Col>
                  <KlaritiTextInput
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder="client@gmail.com"
                  />
                </Col>
              </Row>

              <Row className='p-1'>
                <Col>
                  <KlaritiTextInput
                    label="Postcode"
                    name="postcode"
                    placeholder="W12 7AA"
                  />
                </Col>
                <Col>
                  <KlaritiTextInput
                    label="Date of Birth (fix)"
                    name="dateOfBirth"
                    type="date"
                    placeholder="DD/MM/YY"
                  />
                </Col>

              </Row>
              <hr></hr>
              <Card.Subtitle className="mb-2 text-muted">Subtitle: Sample Details</Card.Subtitle>
              <Row className='p-1'>
                <Col>
                  <KlaritiSelect label="Client Gender" name="clientGender">
                    <option value="">Select a gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </KlaritiSelect>
                </Col>
                <Col>
                  <KlaritiSelect label="Procedure" name="procedureType">
                    <option value="">Select a procedure</option>
                    <option value="procedure1">Procedure 1</option>
                    <option value="procedure2">Procedure 2</option>
                    <option value="procedure3">Procedure 3</option>
                    <option value="other">Other</option>
                  </KlaritiSelect>
                </Col>
                <Col>
                  <KlaritiSelect label="Surgeon" name="surgeon">
                    <option value="">Select a surgeon</option>
                    <option value="surgeon1">Surgeon 1</option>
                    <option value="surgeon2">Surgeon 2</option>
                  </KlaritiSelect>
                </Col>
              </Row>
              <Row className='p-1'>
                <Col>
                  <KlaritiTextInput
                    label="Date of Procedure (fix)"
                    name="dateOfProcedure"
                    type="date"
                    placeholder="DD/MM/YY"
                  />

                </Col>

                <Col>
                  <KlaritiSelect label="Time Of Procedure" name="timeOfProcedure">
                    <option value="">Select a time</option>
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                  </KlaritiSelect>
                </Col>


              </Row>

              <br></br>
              <br></br>

              <KlaritiCheckbox name="acceptedTerms">
                The client accepts the terms and conditions
           </KlaritiCheckbox>

              <br></br>
              <br></br>

              {/* <Button
            variant="primary"
            type="submit"
            >
              Submit
            </Button> */}

              <Button variant="primary" text='light' size="lg" block type="submit" disabled={props.isSubmitting}>
                {props.isSubmitting ? `Submitting` : 'Submit'}
              </Button>


            </Form>
          )}

        </Formik>

      </Card.Body>
    </Card>

   

    </>
  );
};

export default FormikClientInfo;


