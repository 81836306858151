import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { withAuthenticator } from '@aws-amplify/ui-react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import Container from 'react-bootstrap/Container'

import KlaritiNavbar from './components/KlaritiNavbar';
import KlaritiGreeting from './components/KlaritiGreeting';
import FormikClientInfo from './components/FormikClientInfo';
import KlaritiTable from './components/KlaritiTable';

// import * as queries from './graphql/queries';
import { getHospital } from './graphql/queries';
import KlaritiCourier from './components/KlaritiCourier';

function App() {

  const [user, setUser] = useState();
  const [clinic, setClinic] = useState();
  const [surgeons, setSurgeons] = useState([]);


  useEffect(() => {
    getUserClinicDetails();
  },[]);


  // put the clinic and surgeons in here too; 
  //I'll need them, I think, to get the bookings. 
  //And actually here is good, they need to be seen by
  //greetings and  by booking 
  //get hospital CODE too don't forget that


  async function getUserClinicDetails() {
    //const userInfo = await Auth.currentUserInfo();
    const userInfo = await Auth.currentAuthenticatedUser(); 
    setUser(userInfo.username);
    const groupID= userInfo.signInUserSession.accessToken.payload['cognito:groups'];
    const clinicDetails = await API.graphql({ query: getHospital, variables: {id:'tgh'} });
    
    setClinic(clinicDetails.data.getHospital.hospitalName);
    setSurgeons(clinicDetails.data.getHospital.surgeons);

  }

  return (

   
      <Router>
        <KlaritiNavbar user={user} fixed="top" />
        <Container fluid>
        <Route 
        exact path="/" 
        render={(props) => (
          <KlaritiGreeting{...props} user={user} clinic={clinic} surgeons={surgeons} />
        )}
        />
       <Route 
        exact path="/makebooking" 
        render={(props) => (
          <FormikClientInfo{...props} surgeons={surgeons} />
        )}
        />  

        {/* <Route exact path="/makebooking" component={FormikClientInfo} /> */}
        <Route exact path="/viewbookings" component={KlaritiTable}/>
        <Route exact path="/courier" component={KlaritiCourier}/>
        </Container>
      </Router>
 

  );
}



export default withAuthenticator(App);