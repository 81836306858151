import React from 'react';
import Alert from 'react-bootstrap/Alert';


function KlaritiCourier() {

    return(
        <>
        <Alert variant="secondary" className="p-1 m-3 w-75">
        <Alert.Heading>This will contain the courier information</Alert.Heading>
        Information included here will include phone nmbers, courier details and similar.
        <br />
        
        </Alert>
        </>
    );

}


export default KlaritiCourier;
