import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';


import { listBookings } from '../graphql/queries';

import BookingRow from './BookingRow';


function BookingTableRow(props) {

  return (
    <tr>
      <th scope="row">{props.id}</th>
      <td>{props.firstName}</td>
      <td>{props.lastName}</td>
      <td>{props.email}</td>
      <td>{props.dateOfBirth}</td>
      <td>{props.postcode}</td>
      <td>{props.clientGender}</td>
      <td>{props.surgeon}</td>
      <td>{props.dateOfProcedure}</td>
      <td>{props.timeOfProcedure}</td>
      <td>{props.acceptedTerms}</td>

    </tr>
  );
}

//WHat I've done is I've tried porting over the electron data but 
//actually I don't need to do the 'adding' to anything,
//I simply need to pull down the data via the API
//then use the map to map each one actually
//I do need to do 


function KlaritiTable() {


  const [bookings, setBookings] = useState([]);

 
  useEffect(() => {
    fetchBookings();
  }, []);

  async function fetchBookings() {
    const apiData = await API.graphql({ query: listBookings });
    console.log(apiData);
    setBookings(apiData.data.listBookings.items);
    console.log('hi from fetchBookings in KlaritiTable');
    console.log(bookings);
  }
 
  const bookingTable = bookings.map(booking => 
    <BookingRow
    id={booking.id}
    firstName={booking.firstName}
    lastName={booking.lastName}
    email={booking.email}
    dateOfBirth={booking.dateOfBirth}
    postcode={booking.postcode}
    clientGender={booking.clientGender}
    surgeon={booking.surgeon}
    dateOfProcedure={booking.dateOfProcedure}
    timeOfProcedure={booking.timeOfProcedure}
    acceptedTerms={booking.acceptedTerms} />
    );

    

  // const clientSampleTabularList = clientSamples.map(clientSample =>
  //   <BookingTableRow
      // id={clientSample.id}
      // firstName={clientSample.firstName}
      // lastName={clientSample.lastName}
      // email={clientSample.email}
      // dateOfBirth={clientSample.dateOfBirth}
      // postcode={clientSample.postcode}
      // clientGender={clientSample.clientGender}
      // surgeon={clientSample.surgeon}
      // dateOfProcedure={clientSample.dateOfProcedure}
      // timeOfProcedure={clientSample.timeOfProcedure}
      // acceptedTerms={clientSample.acceptedTerms}
  //   />
  // );


  return (
    <Card className="card p-1 m-3 w-100">
       <Alert variant="warning" className="p-1 m-3 w-75">
        <Alert.Heading> (View Bookings Page) Testers, please read</Alert.Heading>
        Any notes on appearance, layout, content, etc., let me know.
        
        Known issues being fixed on this page:
   <ul>
            <li>ID is the autogenerated hashed ID; not the human readable 'useful' ID we discussed (I will fix this- I need to alter the
              database backend to have a secondary index key which is the 'useful' ID) </li>
              <li>Search function not working yet </li>
              <li>Need to collapse table so it can't be left in view by mistake; eg on a timer or simply on button click</li>
          </ul>
        
      </Alert>

      <Card.Body>
        <Card.Title> Title: Client and Sample List as a Table (Card title)</Card.Title>
        {/* <Card.Img variant="top" src="/Users/islabarnard/Desktop/Klariti/react/klariti-clinic/assets/png/01-05.png" fluid/> */}
        <Card.Subtitle> This is the table card subtitle  </Card.Subtitle>
        <Card.Text>
          This is the table card text.
      </Card.Text>

        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Date of Birth</th>
              <th scope="col">Postcode</th>
              <th scope="col">Gender</th>
              <th scope="col">Surgeon</th>
              <th scope="col">Procedure Date</th>
              <th scope="col">Procedure Time</th>
              <th scope="col">Accepted Terms</th>
            </tr>
          </thead>
          <tbody>
          {bookingTable}
          </tbody>
        </Table>

      </Card.Body>
    </Card>

  );
}

export default KlaritiTable;
